import Vue from 'vue';
import VueNotifications from 'vue-notifications';

export default {
  getLocationsStat(ctx, props = { ids: [] }) {
    ctx.$store.commit('toggleLoadingPortalLocationsStat');
    const config = props.query ?
      {
        params: {
          profiles: ctx.filters.profiles.length ? ctx.filters.profiles.map((profile) => profile.id) : [],
          // limit: ctx.filters.limit,
          // offset: ctx.filters.offset,
          // sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
          // q: ctx.formattedQ,
          ids: props.ids,
          location_id: ctx.filters?.location?.data?.id ? ctx.filters.location.data.id : [],
          location: ctx.filters?.location?.data?.id ? ctx.filters.location.data.id : [],
          with_childs: ctx.filters.location?.with_childs,
          start: moment(ctx.filters.date.startDate).unix(),
          stop: moment(ctx.filters.date.endDate).unix()
        }
      } :
      { params: { ids: props.ids } };
    // if (props.query && ctx.filters.search !== '') {
    //   config.params.search = ctx.filters.search;
    // }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/loc_stat`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalUserAccountsList', response.data.data);
          // console.log(response.data)
          ctx.$store.commit('pushPortalLocationsStatList', response.data.data);
          // ctx.totalAccountsResult = response.data.total;
          // if (ctx.$store.state.portalUserAccountsList.length >= response.data.total) {
          //   ctx.canLoadMoreAccounts = false;
          // } else {
          //   ctx.canLoadMoreAccounts = true;
          // }
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalLocationsStat');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalLocationsStat');
      }
    );
  }
};
